import React from 'react';
import defaultPic from 'assets/images/default/default-user.png';
// import moment from 'moment-jalaali';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import classNames from 'classnames';

const ChatBubble = ({ data, admin, user, ApiURL }) => {
  return (
    <div
      className={classNames('chat-bubble-container', {
        'row-reverse': admin
      })}>
      <div className={classNames('bubble-img')}>
        <img
          className="size-40 rounded-circle"
          src={user?.profile_picture ? ApiURL + user.profile_picture : defaultPic}
          alt="Pentagon"
          title="Pentagon"
        />
      </div>

      <div
        className={classNames('bubble-container ', {
          admin: admin,
          user: user
        })}>
        <h5 className="title">{admin ?? data.user.first_name + ' ' + data.user.last_name}</h5>

        {admin && (
          <div
            className={`${data?.direction}`}
            dangerouslySetInnerHTML={{
              __html: `${data?.text}`
            }}></div>
        )}
        {user && <div className={`${data?.direction}`}>{data?.text}</div>}

        {data?.attachment && (
          <div>
            <a target="_blank" href={ApiURL + data?.attachment}>
              <i className="zmdi zmdi-attachment zmdi-hc-fw zmdi-hc-lg" />
            </a>
          </div>
        )}
        {/* <div className="time">
          {data?.created_at} | {moment(data?.created_at).format('jYYYY-jMM-jDD  HH:mm')}
        </div> */}
        {data?.seen && (
          <div className="delivery-time">
            <DoneAllIcon />
            {/* <span>{moment(data?.seen).format('jYYYY-jMM-jDD HH:mm:ss')}</span> */}
          </div>
        )}
      </div>
    </div>
  );
};

export default ChatBubble;
