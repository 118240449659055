import React, { useState } from "react";
import axios from "axios";
import { API_NEW_TICKET, token, boot } from "constants/constants";
import AppDropDown from "components/AppDropDown";
import AppButton from "components/AppButton";
import AppTextEditor from "components/AppTextEditor";
import AppTextInput from "components/AppTextInput";
import BrowseButton from "components/BrowseButton";

const NewTicket = (props) => {
  const search = props.location?.search;
  const params = new URLSearchParams(search);
  const userEmail = params.get("email");

  const ticketCategories = boot?.ticketCategories?.filter(
    (category) => category.parent_id === null
  );

  const departments = boot?.departments;

  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState(userEmail);
  const [department, setDepartment] = useState("");
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");

  const [subject, setSubject] = useState("");
  const [values, setValues] = useState({ success: false, error: false });

  const [ticketValues, setTicketValues] = useState({ text: "" });
  const [editorValue, setEditorValue] = useState(null);

  const subCategories = boot?.ticketCategories?.filter(
    (item) => item.parent_id === category
  );
  function onChangeFile(e) {
    setFile(e.target.files[0]);
  }
  function onConfirm() {
    setValues({ ...values, success: false, error: false });
  }

  const handleSubmit = async () => {
    setLoading(true);

    try {
      const formData = new FormData();

      formData.append("attachment", file);
      formData.append("text", ticketValues.text);
      formData.append("email", email);
      formData.append("subject", subject);
      formData.append("category_id", subCategory);
      formData.append("department_id", department);

      const res = await axios.post(API_NEW_TICKET, formData, token);

      if (res.status === 200 && res.data.status === "success")
        props.history.push(`/app/tickets/ticket-page/${res.data.data.id}`);
    } catch (error) {
      console.log(error, "error in sending new tickets");
      setValues({ ...values, error: true });
      setLoading(false);
    }
  };

  return (
    <div className="jr-card p-0">
      <div className="d-flex  flex-wrap p-5 w-100">
        <h3 className=" col-12 text-center mb-5">Send New Ticket</h3>
        <AppTextInput
          label="User Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="my-3 mt px-2 col-6"
        />
        <AppDropDown
          label="Department"
          value={department}
          onChange={(e) => setDepartment(e.target.value)}
          className=" col-6 px-2"
          items={departments}
          schema={{ value: "id", label: "name_en" }}
        />
        <AppDropDown
          label="Category"
          value={category}
          onChange={(e) => setCategory(e.target.value)}
          className="col-6 px-2"
          items={ticketCategories}
          schema={{ label: "category_en", value: "id" }}
        />

        <AppDropDown
          label="Sub Category"
          value={subCategory}
          onChange={(e) => setSubCategory(e.target.value)}
          className="col-6 px-2"
          items={subCategories}
          schema={{ label: "category_en", value: "id" }}
          disabled={!category}
        />
        <AppTextInput
          label="Subject"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          className="col-12 px-2"
        />

        <div className=" my-2 col-12">
          <AppTextEditor
            value={editorValue}
            onChange={(value) => {
              setEditorValue(value);
              setTicketValues({
                ...ticketValues,
                text: value.toString("html"),
              });
            }}
          />
        </div>

        <BrowseButton onChange={onChangeFile} file={file} />

        <div className="col-12 d-flex justify-content-center mt-3">
          <AppButton text={"Submit"} loading={loading} onClick={handleSubmit} />
        </div>
      </div>
    </div>
  );
};

export default NewTicket;
