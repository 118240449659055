import React, { useState } from "react";
import WidgetHeader from "components/WidgetHeader";
import axios from "axios";
import { API_TICKET_CHANGE_STATUS, token } from "constants/constants";
import AppButton from "components/AppButton";
import DescriptionDialog from "components/DescriptionDialog";
import { useSelector } from "react-redux";

const TicketActions = ({ ticket_id, state, refresh }) => {
  let lastItem = state && state[state.length - 1];

  const [values, setValues] = useState({ resolution: null });
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ open: false });
  const [statusLoading, setStatusLoading] = useState({
    user: false,
    close: false,
    admin: false,
  });
  const { token } = useSelector((state) => state.auth);

  const handleClickOpen = () => {
    setAlert({ open: true });
  };

  const handleRequestClose = () => {
    setAlert({ open: false });
  };
  const handleRequestDone = () => {
    if (values.resolution !== null) {
      changeStatus("Close");
      setAlert({ open: false });
    }
  };

  const handleChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };

  function changeStatus(status, role) {
    setLoading(true);
    if (status === "Admin Hold") {
      setStatusLoading({ user: false, close: false, admin: true });
    } else if (status === "User Hold") {
      setStatusLoading({ user: true, close: false, admin: false });
    } else if (status === "Close") {
      setStatusLoading({ user: false, close: true, admin: false });
    } else if (status === "Unhold" && role === "Admin") {
      setStatusLoading({ user: false, close: false, admin: true });
    } else if (status === "Unhold" && role === "User") {
      setStatusLoading({ user: true, close: false, admin: false });
    }

    axios
      .post(
        API_TICKET_CHANGE_STATUS,
        { ticket_id, status, resolution: values.resolution },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        if (response.status === 200 && response.data.status === "success") {
          setValues({ ...values, success: true });
          refresh();
        } else {
          setValues({ ...values, error: true });
        }

        setStatusLoading({ user: false, close: false, admin: false });
        setLoading(false);
      })
      .catch((error) => {
        let failedValue = "";
        if (typeof error.response.data.data === "string") {
          failedValue = error.response.data.data;
        } else {
          for (let prop in error.response.data.data) {
            failedValue += prop + ": " + error.response.data.data[prop] + " - ";
          }
        }
        setValues({ ...values, error: true, code: failedValue });
        setStatusLoading({ user: false, close: false, admin: false });
        setLoading(false);
      });
  }
  console.log(values);

  return (
    <>
      {state[0] && lastItem?.status !== "Close" && (
        <>
          <WidgetHeader title="Hold Ticket" />
          <div className="jr-card p-0">
            <div className="card-body row">
              <div className="col-md-6 my-1 text-center">
                {lastItem?.status === "Admin Hold" ? (
                  <AppButton
                    status="warning"
                    onClick={() => changeStatus("Unhold", "Admin")}
                    text="UnHold"
                    loading={statusLoading.admin}
                  />
                ) : (
                  lastItem?.status !== "User Hold" && (
                    <AppButton
                      status="warning"
                      onClick={() => changeStatus("Admin Hold", "")}
                      text="Admin Hold"
                      loading={statusLoading.admin}
                    />
                  )
                )}
              </div>
              <div className="col-md-6 my-1 text-center">
                {lastItem?.status === "User Hold" ? (
                  <AppButton
                    status="warning"
                    onClick={() => changeStatus("Unhold", "User")}
                    text="UnHold"
                    loading={statusLoading.user}
                  />
                ) : (
                  lastItem?.status !== "Admin Hold" && (
                    <AppButton
                      status="warning"
                      onClick={() => changeStatus("User Hold", "")}
                      text="User Hold"
                      loading={statusLoading.user}
                    />
                  )
                )}
              </div>
            </div>
          </div>
        </>
      )}
      {state[0] &&
      (lastItem?.status === "Admin Hold" ||
        lastItem?.status === "User Hold") ? null : (
        <>
          <DescriptionDialog
            name="resolution"
            isOpen={alert.open}
            onClose={handleRequestClose}
            onSubmit={handleRequestDone}
            dialogTitle={"Resolution"}
            dialogContent="Please write ticket resolution"
            description={values.resolution}
            onDescriptionChange={handleChange}
          />

          <WidgetHeader title="Open/Close Ticket" />
          <div className="jr-card p-0">
            <div className="card-body row">
              <div className="col-md-6  text-center">
                {lastItem?.status === "Close" ? (
                  <AppButton
                    status="danger"
                    onClick={() => changeStatus("Reopen", "reopen")}
                    text="ReOpen"
                    loading={loading}
                  />
                ) : (
                  <AppButton
                    status="success"
                    onClick={handleClickOpen}
                    text="Close Ticket"
                    loading={statusLoading.close}
                  />
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default TicketActions;
