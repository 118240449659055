import React, { useMemo } from "react";
import MaterialTable from "material-table";
import { API_GET_OPEN_TICKET, boot } from "constants/constants";
import {
  createDateLookup,
  getUser,
  getUserEmail,
  lookup,
  lookup2,
  lookup3,
  lookup4,
  updateDateLookup,
  renderTicket,
} from "constants/usefulFunctions";
import withTable from "HOC/withTable/withTable";
import { GET_OPEN_TICKETS_LIST } from "config/apiRoutes";
import PaginationTable from "components/PaginationTable";

const Tickets = ({ data, loading, options }) => {
  const columns = useMemo(
    () => [
      {
        accessorKey: "fullname",
        header: "User's Full Name",
        Cell: ({ row }) => getUser(row.original.user),
      },
      {
        accessorKey: "user.email",
        header: "Email",
        Cell: ({ row }) => getUserEmail(row.original.user),
      },
      {
        accessorKey: "subject", //normal accessorKey
        header: "Subject",
        Cell: ({ row }) => renderTicket(row.original),
      },
      {
        accessorKey: "assignee_id",
        header: "Assignee",
        // Cell: (rowData) =>
        //   boot.admins && rowData.assignee_id
        //     ? boot.admins.find((o) => o.id === rowData.assignee_id).first_name +
        //       " " +
        //       boot.admins.find((o) => o.id === rowData.assignee_id).last_name
        //     : "-",
        // lookup: lookup2(boot.admins),
      },
      {
        accessorKey: "department_id",
        header: "Department",
        // render: (rowData) =>
        //   boot.departments && rowData.department_id
        //     ? boot.departments.find((o) => o.id === rowData.department_id).name_en
        //     : "-",
        // lookup: lookup(boot.departments),
      },
      {
        accessorKey: "status",
        header: "Status",
      },
      {
        accessorKey: "timeago_en",
        header: "Elapsed",
        Cell: ({ row }) =>
          row.original.status !== "Close" ? row.original.timeago_en : "-",
      },
      { accessorKey: "thread_count", header: "Thread Count" },
      createDateLookup(),
      updateDateLookup(),
      { accessorKey: "priority", header: "Priority" },
      { header: "Resolutions", accessorKey: "resolutions" },
      { header: "Tags", accessorKey: "tags" },
      { header: "Source", accessorKey: "source" },
      { header: "Comment", accessorKey: "comment" },

      {
        header: "Category",
        accessorKey: "parent_category.category_en",
        // lookup: lookup4(boot.ticketCategories),
      },
      {
        header: "Sub Category",
        accessorKey: "category.category_en",
        // lookup: lookup3(boot.ticketCategories),
      },
      { header: "Last Update", accessorKey: "last_update" },
      { header: "Resolution", accessorKey: "resolution" },
    ],
    []
  );

  return (
    <PaginationTable
      // ref={paginationTableRef}
      title="Open Tickets"
      apiEndpoint={GET_OPEN_TICKETS_LIST}
      columns={columns}
    />
  );
};

export default Tickets;
