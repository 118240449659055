import { StorageBaseURL } from "constants/constants";
import { getAttachment } from "constants/usefulFunctions";
import React from "react";

const LabelValue = ({ label, value, icon, customClassName }) => {
  const isFile = label === "File";
  return (
    <div className="label-value">
      <i className={`${icon}`} />
      <div className="text-container">
        <span className="label">{`${label} : `}</span>
        {isFile ? (
          getAttachment(value, StorageBaseURL)
        ) : (
          <span className="value">{value}</span>
        )}
      </div>
    </div>
  );
};

export default LabelValue;
