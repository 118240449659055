import React, { useState } from "react";
import WidgetHeader from "components/WidgetHeader";
import IconButton from "@mui/material/IconButton";
import axios from "axios";
import { API_TICKET_ADD_COMMENT, boot, token } from "constants/constants";
import { useSelector } from "react-redux";

const DefaultTimeLineItem = ({ styleName, timeLine }) => {
  const { created_at, comment, admin_id } = timeLine;
  return (
    <div className="pb-4">
      <div className="timeline-panel ">
        <div className="timeline-panel-header">
          <div className="timeline-heading">
            <h5>{created_at}</h5>
            <p className="timeline-title">
              {boot.admins && admin_id
                ? boot.admins.find((o) => o.id === admin_id).first_name +
                  " " +
                  boot.admins.find((o) => o.id === admin_id).last_name
                : "-"}
            </p>
          </div>
        </div>
        <div className="timeline-body">
          <h3>{comment} </h3>
        </div>
      </div>
    </div>
  );
};
const Comments = ({ ticket_id, refresh, state }) => {
  let lastItem = state && state[state.length - 1];

  const [values, setValues] = useState({});
  const [loading, setLoading] = useState(false);
  const { token } = useSelector((state) => state.auth);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  function sendComment() {
    setLoading(true);
    refresh();
    axios
      .post(
        API_TICKET_ADD_COMMENT,
        { ticket_id, comment: values.comment },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        if (response.status === 200 && response.data.status === "success") {
          setValues({ ...values, success: true, text: "" });
        } else {
          setValues({ ...values, error: true });
        }
        setLoading(false);
      })
      .catch((error) => {
        let failedValue = "";
        if (typeof error.response.data.data === "string") {
          failedValue = error.response.data.data;
        } else {
          for (var prop in error.response.data.data) {
            failedValue += prop + ": " + error.response.data.data[prop] + " - ";
          }
        }
        setValues({ ...values, error: true, code: failedValue });
        setLoading(false);
      });
  }

  return (
    <div className="jr-entry-sec">
      {!state[0] ? (
        ""
      ) : (
        <>
          <WidgetHeader title="Comments" />
          <div>
            <div className="timeline-section clearfix animated slideInUpTiny animation-duration-3">
              {state &&
                state.map(
                  (item) =>
                    item.status === "Comment" && (
                      <DefaultTimeLineItem timeLine={item} />
                    )
                )}
            </div>
            <div className="chat-main-footer jr-border-radius">
              <div
                className="d-flex flex-row align-items-center"
                style={{ maxHeight: 51 }}
              >
                <div className="col">
                  <div className="form-group">
                    <textarea
                      id="required"
                      className="border-0 form-control chat-textarea"
                      // onKeyUp={}
                      value={values.text}
                      onChange={handleChange("comment")}
                      placeholder="Leave a Comment"
                    />
                  </div>
                </div>
                <div className="chat-sent">
                  <IconButton
                    disabled={
                      lastItem && lastItem.status === "Close" ? true : false
                    }
                    onClick={sendComment}
                    aria-label="Send message"
                  >
                    <i className="zmdi  zmdi-mail-send" />
                  </IconButton>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Comments;
