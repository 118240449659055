import React, { useState } from "react";
import { API_GET_REPLY_TICKET, ApiURL, token, boot } from "constants/constants";
import { Card } from "reactstrap";
import axios from "axios";
import StatusLoading from "../../../../../ProfileUser/components/StatusLoading";
import BrowseButton from "components/BrowseButton";
import AppButton from "components/AppButton";
import AppTextEditor from "components/AppTextEditor";
import ChatBubble from "./ChatComponent/chatBubble";
import ChatInfo from "./ChatComponent/chatInfo";
import { useSelector } from "react-redux";

const ChatSection = ({ ticket_id, refresh, state }) => {
  let lastItem = state && state[state.length - 1];
  const [values, setValues] = useState({});
  const [file, setFile] = useState("");
  const [loading, setLoading] = useState(false);
  const [ticketValues, setTicketValues] = useState({ text: "" });
  const [editorValue, setEditorValue] = useState(null);
  const { token } = useSelector((state) => state.auth);

  function sendReply() {
    const text = ticketValues.text;
    let minLenght;
    if (text.includes("&nbsp;")) {
      minLenght = 7;
    } else {
      minLenght = 0;
    }
    const newTxt = text.replace(/&nbsp;/gi, "");
    if (newTxt.length > minLenght) {
      setLoading(true);
      const formData = new FormData();
      formData.append("ticket_id", ticket_id);
      formData.append("attachment", file);
      formData.append("text", ticketValues.text);

      return axios
        .post(API_GET_REPLY_TICKET, formData, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then(() => {
          setValues({ text: "" });
          refresh();
          setLoading(false);
          setEditorValue(null);
          setTicketValues({ text: "text" });
        })
        .catch((error) => {
          let failedValue = "";

          if (typeof error.response.data.data === "string") {
            failedValue = error.response.data.data;
          } else {
            for (let prop in error.response.data.data) {
              failedValue +=
                prop + ": " + error.response.data.data[prop] + " - ";
            }
          }
          setValues({ ...values, error: true, code: failedValue });
        });
    }
  }

  function onChangeFile(e) {
    setFile(e.target.files[0]);
  }

  return (
    <div className="chat-section-container">
      {state[0] && (
        <div className={`chat-title ${state[0].direction}`}>
          <span>{state[0].subject}</span>
        </div>
      )}
      {!state[0] ? (
        <StatusLoading />
      ) : (
        <div className="chat-box-main ">
          <div className="chat-list-scroll">
            <div>
              {state &&
                state.map((post) => {
                  // const AdminDetail = boot?.admins.find((o) => {
                  //   if (post.admin_id) return o.id === post.admin_id;
                  // });
                  // const AssigneeDetail = boot?.admins.find((o) => {
                  //   if (post.admin_id) return o.id === post.assignee_id;
                  // });

                  // let Admin = `${AdminDetail?.first_name} ${AdminDetail?.last_name}`;
                  // let Assignee = `${AssigneeDetail?.first_name} ${AssigneeDetail?.last_name}`;

                  if (post.ticket_id && post.status === "Comment") {
                    return (
                      <ChatInfo
                      // text={`${Admin} Added a comment at ${post.created_at}`}
                      />
                    );
                  }
                  if (post.ticket_id && post.status === "Escalate") {
                    return (
                      <ChatInfo
                      // text={`${Admin} Escalated to ${Assignee} at ${post.created_at}`}
                      />
                    );
                  }
                  if (post.ticket_id && post.status === "Admin Hold") {
                    return (
                      <ChatInfo
                      // text={`${Admin} Held admin ticket at ${post.created_at}`}
                      />
                    );
                  }
                  if (post.ticket_id && post.status === "User Hold") {
                    return (
                      <ChatInfo
                      // text={`${Admin} Held user ticket at ${post.created_at}`}
                      />
                    );
                  }
                  if (post.ticket_id && post.status === "Hold") {
                    return (
                      <ChatInfo
                      // text={`${Admin} Held ticket at ${post.created_at}`}
                      />
                    );
                  }
                  if (post.ticket_id && post.status === "Close") {
                    return (
                      <ChatInfo
                      // text={`${Admin} Closed ticket at ${
                      //   post.created_at
                      // } (${post.resolution && post.resolution})`}
                      />
                    );
                  }
                  if (post.ticket_id && post.status === "Unhold") {
                    return (
                      <ChatInfo
                      // text={`${Admin} Unheld ticket at ${post.created_at}`}
                      />
                    );
                  }
                  if (post.ticket_id && post.status === "Reopen") {
                    return (
                      <ChatInfo
                      // text={`${Admin} Reopened ticket at ${post.created_at}`}
                      />
                    );
                  }

                  if (post.admin_id) {
                    return (
                      <ChatBubble
                        data={post}
                        // admin={Admin}
                        ApiURL="https://admin.api.yekpay.com/"
                      />
                    );
                  }
                  if (!post.admin_id) {
                    return (
                      <>
                        <ChatBubble
                          data={post}
                          user={post.user}
                          ApiURL={ApiURL}
                        />
                      </>
                    );
                  }
                })}
            </div>
            <Card className="jr-card m-0">
              <AppTextEditor
                value={editorValue}
                onChange={(value) => {
                  setTicketValues({
                    ...ticketValues,
                    text: value.toString("html"),
                  });
                  setEditorValue(value);
                }}
              />

              <div className="action-button">
                <BrowseButton onChange={onChangeFile} file={file} />

                <AppButton
                  disabled={lastItem?.status === "Close"}
                  customClassName="ml-auto "
                  onClick={sendReply}
                  loading={loading}
                  text="Reply"
                />
              </div>
            </Card>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatSection;
