import React, { createElement, useEffect, useState } from "react";
import ListSubheader from "@mui/material/ListSubheader";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import { primaryColor } from "assets/colors";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Badge, Typography, styled } from "@mui/material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AssistantIcon from "@mui/icons-material/Assistant";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import CurrencyExchange from "@mui/icons-material/CurrencyExchange";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import DataObjectIcon from "@mui/icons-material/DataObject";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeIcon from "@mui/icons-material/Home";
import GroupIcon from "@mui/icons-material/Group";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import AddCardIcon from "@mui/icons-material/AddCard";
import ListIcon from "@mui/icons-material/List";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import SubtitlesIcon from "@mui/icons-material/Subtitles";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import SyncProblemIcon from "@mui/icons-material/SyncProblem";
import SellIcon from "@mui/icons-material/Sell";
import DataArrayIcon from "@mui/icons-material/DataArray";
import AddHomeWorkIcon from "@mui/icons-material/AddHomeWork";
import AddBoxIcon from "@mui/icons-material/AddBox";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import CustomScrollbars from "util/CustomScrollbars";
import { getBadgesAction } from "redux/actions/GeneralActions";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import WalletIcon from "@mui/icons-material/Wallet";
import HasPermissions from "components/HasPermissions";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";

const SidenavContent = () => {
  const {
    user: { first_name, last_name },
  } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const location = useLocation();

  // const pathname = `${history.location.pathname}`;
  // useEffect(() => {
  //   const current = document.getElementById(pathname);
  //   current?.classList?.add("open");

  //   if (current?.classList?.contains("sub-menu"))
  //     current.parentElement.classList.add("open");
  // }, [pathname]);

  // State to track the currently open submenu
  const [openSubMenu, setOpenSubMenu] = useState(-1);

  const handleClickSubMenu = (index) => {
    // Toggle the submenu only if it's different from the currently open one
    setOpenSubMenu((prevIndex) => (prevIndex === index ? -1 : index));
  };

  return (
    <List
      sx={{ width: "100%", maxWidth: 360 }}
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader
          component="div"
          id="nested-list-subheader"
          sx={{ backgroundColor: "unset", color: "unset" }}
        >
          <Box sx={{ display: "flex", py: 3 }}>
            <Avatar
              src="https://via.placeholder.com/150x150"
              sx={{ width: 44, height: 44, mr: 2 }}
            />
            {first_name} {last_name}
          </Box>
        </ListSubheader>
      }
      // dense
    >
      {MenuList.map((item, index) => {
        let isSelected = item.content?.find(
          (item) => `/${item.to}` === location.pathname
        );
        return item.content ? (
          <CustomListItemWithSubMenu
            title={item.title}
            icon={item.icon}
            content={item.content}
            open={openSubMenu === index || isSelected} // Pass whether this submenu is open
            onClick={() => handleClickSubMenu(index)} // Pass the index
          />
        ) : (
          <CustomListItem
            title={item.title}
            icon={item.icon}
            onClick={() => {
              navigate(item.to);
              handleClickSubMenu(index);
            }}
            selected={
              openSubMenu === index || `/${item.to}` === location.pathname
            }
          />
        );
      })}
    </List>
  );
};

// export default SidenavContent;

const CustomListItemWithSubMenu = ({ title, icon, content, open, onClick }) => {
  const navigate = useNavigate();
  const location = useLocation();

  // const [open, setOpen] = useState(true);

  // const handleClick = () => {
  //   setOpen(!open);
  // };

  return (
    <>
      <ListItemButton
        sx={{ color: open ? "#fff" : "inherit", px: 3, py: 0.25 }} // Set the color based on open state
        onClick={onClick}
      >
        <ListItemIcon sx={{ color: "inherit", minWidth: "auto", mr: 2 }}>
          {icon}
        </ListItemIcon>
        <ListItemText sx={{ color: "inherit" }} primary={title} />
        <ExpandLess
          sx={{
            color: "inherit",
            transform: open ? `rotate(180deg)` : "rotate(90deg)",
            transition: "ease-in-out .1s transform",
            marginRight: "auto",
          }}
        />
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div">
          {content.map((item) => (
            <ListItemButton
              onClick={() => navigate(item.to)}
              selected={location?.pathname === `/${item.to}`}
              sx={{
                pl: 5,
                "&.Mui-selected": {
                  backgroundColor: primaryColor,
                  color: "#fff",
                },
              }}
              dense
            >
              {/* <ListItemIcon>
                {item.icon && cloneElement(item.icon)}
              </ListItemIcon> */}
              <ListItemText primary={item.title} />
            </ListItemButton>
          ))}
        </List>
      </Collapse>
    </>
  );
};

const CustomListItem = ({ title, icon, onClick, selected }) => {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  const location = useLocation();

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItemButton
        sx={{ color: selected ? "#fff" : "inherit" }} // Set the color based on open state
        onClick={onClick}
      >
        <ListItemIcon sx={{ color: "inherit", pl: 2 }}>{icon}</ListItemIcon>
        <ListItemText sx={{ color: "inherit" }} primary={title} />
      </ListItemButton>
    </>
  );
};

export const MenuList = [
  {
    title: "Dashboard",
    icon: <DashboardIcon />,
    to: "dashboard",
  },
  // {
  //   title: "Users",
  //   icon: <PeopleIcon />,
  //   content: [
  //     {
  //       title: "All Users",
  //       to: "users/all-users",
  //     },
  //     {
  //       title: "Pending Users",
  //       to: "users/pending-users",
  //     },
  //     {
  //       title: "Approved Users",
  //       to: "users/approved-users",
  //     },
  //     {
  //       title: "Starter Users",
  //       to: "users/starter-users",
  //     },
  //     {
  //       title: "Premium Users",
  //       to: "users/premium-users",
  //     },
  //     {
  //       title: "Declined Users",
  //       to: "users/declined-users",
  //     },
  //   ],
  // },
  // {
  //   title: "Top-up",
  //   icon: <AccountBalanceWalletIcon />,
  //   content: [
  //     {
  //       title: "Top-up",
  //       to: "top-up/create",
  //     },
  //     {
  //       title: "Top Up List",
  //       to: "top-up/list",
  //     },
  //     {
  //       title: "Top Up by Bank Transfer",
  //       to: "top-up/top-up-by-bank-transfer",
  //     },
  //     {
  //       title: "Iban List",
  //       to: "top-up/iban-list",
  //     },
  //   ],
  // },
  // {
  //   title: "Exchange",
  //   icon: <CurrencyExchange />,
  //   content: [
  //     {
  //       title: "Create Exchange Rate",
  //       to: "exchange/create-exchange-rate",
  //     },
  //     {
  //       title: "Exchage List",
  //       to: "exchange/rate-list",
  //     },
  //   ],
  // },
  // {
  //   title: "Admin",
  //   icon: <PersonIcon />,
  //   content: [
  //     {
  //       title: "Add Admin",
  //       to: "admin/add",
  //     },
  //     {
  //       title: "Admin List",
  //       to: "admin/list",
  //     },
  //   ],
  // },
  // {
  //   title: "Cards",
  //   icon: <CreditCardIcon />,
  //   content: [
  //     {
  //       title: "Product Card",
  //       to: "cards/product-cards",
  //     },
  //     {
  //       title: "Brand Cards",
  //       to: "cards/brand-cards",
  //     },
  //     {
  //       title: "Cards Status",
  //       to: "cards/cards-status",
  //     },
  //     {
  //       title: "Physical Cards",
  //       to: "cards/physical-cards",
  //     },
  //     {
  //       title: "Virtual Cards",
  //       to: "cards/virtual-cards",
  //     },
  //   ],
  // },
  {
    title: "Role",
    icon: <AccountBoxIcon />,
    content: [
      {
        title: "Add Role",
        to: "role/add",
      },
      {
        title: "Role List",
        to: "role/list",
      },
    ],
  },
  {
    title: "Agent",
    icon: <AssistantIcon />,
    content: [
      {
        title: "Add Agent",
        to: "agent/add",
      },
      {
        title: "Agent List",
        to: "agent/list",
      },
    ],
  },
  {
    title: "Config",
    icon: <ToggleOnIcon />,
    content: [
      {
        title: "Default Wallets",
        to: "config/default-wallets",
      },
      {
        title: "Default Fees",
        to: "config/default-fee",
      },
      {
        title: "Default Fees List",
        to: "config/default-fees",
      },
    ],
  },
  {
    title: "Logs",
    icon: <DataObjectIcon />,
    content: [
      {
        title: "Users log",
        to: "logs/users-log",
      },
      {
        title: "Pep Logs",
        to: "logs/pep-logs",
      },
    ],
  },
];

const item = {
  py: "2px",
  px: 3,
  color: "rgba(255, 255, 255, 0.7)",
  "&:hover, &:focus": {
    bgcolor: "rgba(255, 255, 255, 0.08)",
  },
};

const itemCategory = {
  boxShadow: "0 -1px 0 rgb(255,255,255,0.1) inset",
  py: 1.5,
  px: 3,
};

export default function Navigator(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [badges, setBadges] = useState({});
  const admin = useSelector((state) => state.auth.user);

  const approvedUsersCount = useSelector(
    (state) => state.customer.approvedUsers.count
  );
  const starterUsersCount = useSelector(
    (state) => state.customer.starterUsers.count
  );
  const pendingUsersCount = useSelector(
    (state) => state.customer.pendingUsers.count
  );

  const categories = [
    {
      id: "User",
      children: [
        {
          id: "All Users",
          to: "users/all-users",
          icon: GroupIcon,
          permissions: "See All Users",
        },
        {
          id: "Pending Users",
          to: "users/pending-users",
          icon: PendingActionsIcon,
          badge: badges.pending,
          permissions: "See Pending Users",
        },
        {
          id: "Approved Users",
          to: "users/approved-users",
          icon: HowToRegIcon,
          badge: badges.approved,
          permissions: "See Approved Users",
        },
        {
          id: "Starter Users",
          to: "users/starter-users",
          icon: RocketLaunchIcon,
          badge: badges.starter,
          permissions: "See Starter Users",
        },
        {
          id: "Premium Users",
          to: "users/premium-users",
          icon: WorkspacePremiumIcon,
          badge: badges.premium,
          permissions: "See Premium Users",
        },
        {
          id: "Declined Users",
          to: "users/declined-users",
          icon: SyncProblemIcon,
          badge: badges.declined,
          permissions: "See Declined Users",
        },
        {
          id: "Elito Pending Users",
          to: "users/pending-elito-users",
          icon: PendingActionsIcon,
          badge: badges.elitoPending,
          permissions: "See Elito Pending Users",
        },
      ],
    },
    {
      id: "Top-up",
      icon: AccountBalanceWalletIcon,
      children: [
        {
          id: "Top-up User Wallet",
          to: "top-up/create",
          icon: AccountBalanceWalletIcon,
          permissions: "Create TopUp",
        },
        {
          id: "Top-up Agent Wallet",
          to: "top-up/create-admin",
          icon: AccountBalanceWalletIcon,
          permissions: "Create TopUp Agent",
        },
        {
          id: "Top Up List",
          to: "top-up/list",
          icon: ListIcon,
          permissions: "See TopUp List",
        },
        {
          id: "Top Up Card List",
          to: "top-up/card/list",
          icon: ListIcon,
          permissions: "See TopUp Card List",
        },
        {
          id: "Top Up Agent List",
          to: "top-up-agent/list",
          icon: ListIcon,
          permissions: "See TopUp Agent List",
        },
        {
          id: "Top Up by Bank Transfer",
          to: "top-up/top-up-by-bank-transfer",
          icon: AccountBalanceIcon,
          permissions: "Create TopUp By BankTransfer",
        },
        {
          id: "Iban List",
          to: "top-up/iban-list",
          icon: SubtitlesIcon,
          permissions: "See Iban List",
        },
      ],
    },
    {
      id: "Exchange",
      icon: CurrencyExchange,
      children: [
        {
          id: "Create Exchange Rate",
          to: "exchange/create-exchange-rate",
          icon: TrendingUpIcon,
          permissions: "See Exchange List",
        },
        {
          id: "Exchage List",
          to: "exchange/rate-list",
          icon: CurrencyExchangeIcon,
          permissions: "Create Exchange Rate",
        },
      ],
    },
    {
      id: "Cards",
      icon: CreditCardIcon,
      children: [
        {
          id: "Request New Virtual Card",
          to: "cards/request-virtual-card",
          icon: AddCardIcon,
          permissions: "Create Credit Cards",
        },
        {
          id: "Request New Physical Card",
          to: "cards/request-physical-card",
          icon: AddCardIcon,
          permissions: "Create Physical Cards",
        },
        {
          id: "Top up Card",
          to: "cards/top-up",
          icon: PointOfSaleIcon,
          permissions: "Request TopUp Card",
        },
        {
          id: "Physical Cards",
          to: "cards/physical-cards",
          icon: ListIcon,
          permissions: "See Physical Cards",
        },
        {
          id: "Virtual Cards",
          to: "cards/virtual-cards",
          icon: ListIcon,
          permissions: "See Virtual Cards",
        },
      ],
    },
    {
      id: "Agent",
      children: [
        {
          id: "Wallets",
          to: "agent/wallets",
          icon: WalletIcon,
          permissions: "See Agent Wallet",
        },
        {
          id: "Add Agent",
          to: "agent/add",
          icon: AddHomeWorkIcon,
          permissions: "Create Agent",
        },
        {
          id: "Agent List",
          to: "agent/list",
          icon: ListIcon,
          permissions: "See Agent List",
        },
        {
          id: "Transactions",
          to: "agent/transactions",
          icon: ListIcon,
          permissions: "See Agent Transaction",
        },
      ],
    },
    {
      id: "Config",
      icon: ToggleOnIcon,
      children: [
        {
          id: "Default Wallets",
          to: "config/default-wallets",
          icon: WalletIcon,
          permissions: "See Default Wallets Config",
        },
        {
          id: "Default Fees",
          to: "config/default-fee",
          icon: SellIcon,
          permissions: "See Default Fees Config",
        },
        {
          id: "Default Fees List",
          to: "config/default-fees",
          icon: ListIcon,
          permissions: "See Default Fees List Config",
        },
      ],
    },
    {
      id: "Admin",
      children: [
        {
          id: "Add Admin",
          to: "admin/add",
          icon: PersonAddIcon,
          permissions: "Create Admin",
        },
        {
          id: "Admin List",
          to: "admin/list",
          icon: PeopleAltIcon,
          permissions: "See Admin List",
        },
      ],
    },
    {
      id: "Role",
      children: [
        {
          id: "Add Role",
          to: "role/add",
          icon: AddBoxIcon,
          permissions: "Create Role",
        },
        {
          id: "Role List",
          to: "role/list",
          icon: RecentActorsIcon,
          permissions: "See Role List",
        },
      ],
    },
    {
      id: "Logs",
      children: [
        {
          id: "Users log",
          to: "logs/users-log",
          icon: DataObjectIcon,
          permissions: "See Users Logs",
        },
        {
          id: "Pep Logs",
          to: "logs/pep-logs",
          icon: DataArrayIcon,
          permissions: "See Pep Logs",
        },
        {
          id: "Admin Activity Logs",
          to: "logs/admin-activity-logs",
          icon: DataArrayIcon,
          permissions: "See Admin Activity Logs",
        },
        {
          id: "Dashboard Activity Logs",
          to: "logs/dashboard-activity-logs",
          icon: DataArrayIcon,
          permissions: "See Dashboard Activity Logs",
        },
      ],
    },
  ];

  useEffect(() => {
    // This will run only once when the component mounts
    dispatch(getBadgesAction({ setBadges }));
  }, [dispatch]);

  useEffect(() => {
    // This will run whenever approvedUsersCount, starterUsersCount, or pendingUsersCount changes
    dispatch(getBadgesAction({ setBadges }));
  }, [approvedUsersCount, starterUsersCount, pendingUsersCount, dispatch]);

  const {
    user: { first_name, last_name },
  } = useSelector((state) => state.auth);
  const { ...other } = props;

  const [openSubMenu, setOpenSubMenu] = useState(-1);

  const handleClickSubMenu = (index) => {
    // Toggle the submenu only if it's different from the currently open one
    setOpenSubMenu((prevIndex) => (prevIndex === index ? -1 : index));
  };

  return (
    <CustomScrollbars className=" scrollbar">
      <List disablePadding>
        <HasPermissions permissions="See Dashboard">
          <ListItem sx={{ py: 2, px: 3 }}>
            <ListItemText sx={{ color: "#fff" }}>Main</ListItemText>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton sx={item} onClick={() => navigate("/dashboard")}>
              <ListItemIcon sx={{ color: "inherit", minWidth: "auto", mr: 2 }}>
                <HomeIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Dashboard</ListItemText>
            </ListItemButton>
          </ListItem>
        </HasPermissions>
        <HasPermissions permissions="See Dashboard">
          <CustomListItemWithSubMenu
            title={"Tickets"}
            icon={<ConfirmationNumberIcon fontSize="small" />}
            content={[
              {
                title: "All Tickets",
                to: "/tickets/all-tickets",
              },
              {
                title: "Open Tickets",
                to: "/tickets/open-tickets",
                // badge: openTicket,
              },
              {
                title: "New Ticket",
                to: "/tickets/new-ticket",
              },
            ]}
            open={openSubMenu}
            onClick={() => handleClickSubMenu(!openSubMenu)}
          />
        </HasPermissions>
        {/* <Divider sx={{}} /> */}
        {categories.map(({ id, children }) => (
          <Box key={id}>
            {children.some((child) =>
              HasPermissions({ permissions: child.permissions })
            ) && (
              <ListItem sx={{ py: 2, px: 3 }}>
                <ListItemText sx={{ color: "#fff" }}>{id}</ListItemText>
              </ListItem>
            )}

            {children.map(
              ({ id: childId, icon, badge, active, to, permissions }) => (
                <>
                  <HasPermissions permissions={permissions} key={childId}>
                    <ListItem disablePadding>
                      <ListItemButton
                        selected={active}
                        sx={item}
                        onClick={() => navigate(to)}
                      >
                        <ListItemIcon
                          sx={{ color: "inherit", minWidth: "auto", mr: 2 }}
                        >
                          {icon && createElement(icon, { fontSize: "small" })}
                        </ListItemIcon>
                        <StyledBadge
                          badgeContent={badge}
                          color="error"
                          max={Infinity}
                        >
                          <ListItemText>{childId}</ListItemText>
                        </StyledBadge>
                      </ListItemButton>
                    </ListItem>
                  </HasPermissions>
                </>
              )
            )}

            {children.some((child) =>
              HasPermissions({ permissions: child.permissions })
            ) && <Divider sx={{ mt: 1 }} />}
          </Box>
        ))}
      </List>
    </CustomScrollbars>
  );
}

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -20,
    top: 15,
    // border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}));
