import React, { useEffect, useState } from "react";
import ChatSection from "./components/chatSection";
import axios from "axios";
import { API_GET_SHOW_TICKET } from "constants/constants";
import TicketPriority from "./components/ticketPriority";
import Department from "./components/department";
import Comments from "./components/comments";
import TicketActions from "./components/actions";
import { useSelector } from "react-redux";

const TicketPage = () => {
  const urlObject = new URL(window.location);
  const parts = urlObject.pathname.split("/");
  const ticket_id = parts[parts.length - 1];

  const [state, setState] = useState([]);
  const { token } = useSelector((state) => state.auth);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    await axios
      .post(
        API_GET_SHOW_TICKET,
        { ticket_id },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((res) => {
        let data = res.data.data;
        setState(data);
      });
  };

  const refresh = () => {
    fetchData();
  };

  return (
    <div className="ticket-main-page row">
      <div className="col-sm-8">
        <ChatSection state={state} ticket_id={ticket_id} refresh={refresh} />
      </div>
      <div className="col-sm-4 ticket-sidebar-container">
        <div className="ticket-sidebar">
          <TicketPriority
            state={state}
            ticket_id={ticket_id}
            refresh={refresh}
          />
          {/* <Department state={state} ticket_id={ticket_id} refresh={refresh} /> */}
          <TicketActions
            state={state}
            ticket_id={ticket_id}
            refresh={refresh}
          />
          <Comments state={state} ticket_id={ticket_id} refresh={refresh} />
        </div>
      </div>
    </div>
  );
};

export default TicketPage;
