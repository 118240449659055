import React from "react";
import RichTextEditor from "react-rte";

const AppTextEditor = ({ value, onChange }) => {
  const toolbarConfig = {
    display: [
      "INLINE_STYLE_BUTTONS",
      "BLOCK_TYPE_BUTTONS",
      "LINK_BUTTONS",
      "BLOCK_TYPE_DROPDOWN",
      "HISTORY_BUTTONS",
    ],
    INLINE_STYLE_BUTTONS: [
      { label: "Bold", style: "BOLD", className: "custom-css-class" },
      { label: "Italic", style: "ITALIC" },
      { label: "Underline", style: "UNDERLINE" },
    ],
    BLOCK_TYPE_DROPDOWN: [
      { label: "Normal", style: "unstyled" },
      { label: "Heading Large", style: "header-one" },
      { label: "Heading Medium", style: "header-two" },
      { label: "Heading Small", style: "header-three" },
    ],
    BLOCK_TYPE_BUTTONS: [
      { label: "UL", style: "unordered-list-item" },
      { label: "OL", style: "ordered-list-item" },
    ],
  };

  return (
    <RichTextEditor
      toolbarConfig={toolbarConfig}
      value={value !== null ? value : RichTextEditor.createEmptyValue()}
      onChange={onChange}
      rootStyle={{
        height: "auto",
        minHeight: "300px",
        marginRight: "-8px",
        marginLeft: "-8px",
      }}
      toolbarStyle={{
        backgroundColor: "#F4F4F7",
        width: "100%",
        margin: "0px",
        padding: "5px",
      }}
      placeholder="Leave a message..."
    />
  );
};

export default AppTextEditor;
