import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getUser } from "constants/usefulFunctions";
import StatusLoading from "../../../../../ProfileUser/components/StatusLoading";
import LabelValue from "components/LabelValue";

const TicketPriority = ({ state }) => {
  return (
    <div className="jr-entry-sec">
      {!state[0] ? (
        <StatusLoading />
      ) : (
        state[0] && (
          <div className="jr-card p-0">
            <div className="card-body">
              <LabelValue
                label="User"
                value={getUser(state[0].user)}
                icon="zmdi zmdi-account-o zmdi-hc-fw zmdi-hc-lg"
              />
              <LabelValue
                label="Mobile"
                value={state[0].user.mobile}
                icon="zmdi zmdi-phone zmdi-hc-fw zmdi-hc-lg"
              />

              <LabelValue
                label="Category"
                value={state[0].user.email}
                icon="zmdi zmdi-email zmdi-hc-fw zmdi-hc-lg"
              />
              <LabelValue
                label="Email"
                value={state[0].category && state[0].category.category_en}
                icon="zmdi zmdi-view-list zmdi-hc-fw zmdi-hc-lg"
              />
              <LabelValue
                label="Priority"
                value={state[0].priority}
                icon="zmdi zmdi-collection-item-1 zmdi-hc-fw zmdi-hc-lg"
              />
              <LabelValue
                label="Created at"
                value={state[0].created_at}
                icon="zmdi zmdi-time zmdi-hc-fw zmdi-hc-lg"
              />
              <LabelValue
                label="Last Update"
                value={state[state.length - 1].created_at}
                icon="zmdi zmdi-time-interval zmdi-hc-fw zmdi-hc-lg"
              />
              <LabelValue
                label="Status"
                value={state[state.length - 1].status}
                icon="zmdi zmdi-traffic zmdi-hc-fw zmdi-hc-lg"
              />
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default TicketPriority;
