import React from 'react';
import Button from '@mui/material/Button';

const BrowseButton = ({ buttonText = 'Browse', onChange, file, label, attachmentUrl, baseUrl }) => (
  <div className="d-flex flex-column align-items-start mx-2">
    {label && <label className=" py-1">{label} : </label>}
    <Button className="bg-light jr-btn jr-btn-sm text-dark" component="label">
      <i className="zmdi zmdi-file-plus  mr-2 jr-fs-xl d-inline-flex align-middle" />
      {buttonText}
      <input type="file" onChange={onChange} style={{ display: 'none' }} />
    </Button>
    {file && (
      <div className="my-2 ">
        File Name: <span className="text-success mr-2">{file?.name}</span>
        File Size: <span className="text-success">{Math.trunc(file?.size / 1024)}KB</span>
      </div>
    )}
    {!file && attachmentUrl && (
      <div className="mt-3">
        <a target="_blank" href={baseUrl + attachmentUrl}>
          Download Last Attachment
          <i className="zmdi zmdi-attachment zmdi-hc-fw zmdi-hc-lg text-primary" />
        </a>
      </div>
    )}
  </div>
);

export default BrowseButton;
